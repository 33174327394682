export function IsPC () {
  var userAgentInfo = navigator.userAgent;
  var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

export function  getBrowser(){
  const userAgent=navigator.userAgent
  let browser=""
  if(userAgent.indexOf("Chrome")!=-1){
      browser="Chrome"
  }else if(userAgent.indexOf("Safari")!=-1){
      browser="Safari"
  }else if(userAgent.indexOf("Firefox")!=-1){
      browser="Firefox"
  }else if(userAgent.indexOf("Edge")!=-1){
      browser="Edge"
  }else if(userAgent.indexOf("MSIE")!=-1){
      browser="MSIE"
  }else if(userAgent.indexOf("Opera")!=-1){
      browser="Opera"
  }
  this.$store.commit('editBrowser',browser)
}

export function dateFormat(fmt, date) {
  let ret;
  let opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      }
  }
  return fmt;
}

//时间戳转日期格式  
export function formatDateTime (date) {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  var h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? ('0' + minute) : minute;
  var second = date.getSeconds();
  second = second < 10 ? ('0' + second) : second;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
}

//在websocket创建后
export function SocketNext () {
  return new Promise((resolve,reject) =>{
    let timeout1 = setInterval(function () {
      if(window.Socket&&window.Socket.emitMessage){
        clearInterval(timeout1)
        resolve()
      }else{
        reject()
      }
    },300)
  })
}

export default function (that) {
  let islogin = sessionStorage.getItem('loginstate')
  window.EventHandlers = {};
  window.SocketListen = function(type,eventHandler){
      if (typeof eventHandler === 'function') {
          EventHandlers[type] = eventHandler
      }
  }
  function close () {
      console.log("webSocket连接关闭");
      clearInterval(window.keepLink)
      trylink()
  }
  function err (event) {
      console.log(event)
  }
  function open (event) {
      console.log("webSocket连接成功"); 
      window.keepLink = setInterval(() => {
          window.Socket.send('ping')
      }, 3000);
  }
  function trylink () {
      if(islogin == 'true'){
          getTunnel().then(result =>{
              if(result.data.data.connectUrl){
                  window.Socket = new WebSocket(result.data.data.connectUrl);
                  Socket.emitMessage = function(messageType, messageContent) {
                      window.Socket.send(
                          ['message',JSON.stringify({
                              type: messageType,
                              content: messageContent
                          })].join(':')
                      )
                  }
                  Socket.onopen = open
                  Socket.onclose = close
                  Socket.onerror = err 
                  Socket.onmessage = event =>{
                      if(event.data != 'pong'){
                          console.log("socket接收的数据=============================",event);
                      }
                      var pos = event.data.indexOf(":");
                      if(pos<1){
                         // console.log("=============================",pos);
                      }else{
                          var packet = JSON.parse(event.data.substr(pos+1));
                          //console.log("=============================",packet);
                          //匹配监听的内容
                          for(let index in EventHandlers){
                              if(index == packet.type){
                                  EventHandlers[index](packet.content)
                              }
                          }
                          switch (packet.type) {
                              case 'matched': //用户开始进行websocket连接，信道服务器连接成功后通知服务端
                                  Socket.onMatched(packet)
                                  break;
                              case 'room':
                                  Socket.onRoom(packet)
                                  break;
                              case 'kline':
                                  Socket.onKline(packet);
                                  break;
                              case 'answer':
                                  Socket.onAnswer(packet);
                                  break;
                              case 'message':
                                  Socket.onMessage(packet);
                                  break;    
                              case 'trade':
                                  Socket.onTrade(packet);
                                  break;
                              case 'finish':
                                  Socket.onFinish(packet);
                                  break;
                              case 'invite':
                                  Socket.onInvite(packet);
                                  break;
                              case 'notice':
                                  Socket.onNotice(packet);
                                  break;
                              case 'challenge':
                                  Socket.onChallenge(packet);
                                  break;
                              case 'offline':
                                  Socket.onOffline(packet);
                                  break;
                              case 'decline':
                                  Socket.onDecline(packet);
                                  break;    
                              case 'prepare':
                                  Socket.onPrepare(packet);
                                  break;
                              case 'close':
                                  getTunnel().then(result =>{
                                      console.log(result.data.data.connectUrl)
                                      sessionStorage.setItem('connectUrl',result.data.data.connectUrl)
                                      this.$createSocket()
                                  })
                                  break
                          }
                      }
                  }
                  
                  Socket.onMatched = (packet) =>{
                      console.log(packet)
                      sessionStorage.setItem('player',JSON.stringify(packet))
                      that.$router.push('/ready')
                      //window.location.href = '/ready'
                  }
                  Socket.onRoom = packet =>{
                      if(packet.content.room.charAt(8) == '1'){//挑战的room
                          sessionStorage.setItem('battlenumber','01004')
                          sessionStorage.setItem('player',JSON.stringify(packet))
                          that.$router.push('/ready')  
                      }
                      if(packet.content.room.charAt(8) == '2'){//混战和比赛的room
                          sessionStorage.setItem('battlenumber','0'+packet.content.room.charAt(8)+'00'+packet.content.room.charAt(11))
                          sessionStorage.setItem('player',JSON.stringify(packet))
                      }
                  }
                  Socket.onKline = (packet) =>{
                      that.$store.commit('setkline',packet.content)
                      that.$router.push('/battle')
                  }
                  Socket.onNotice = packet =>{//收到全服滚动消息
                      that.$store.commit('setnews',packet.content)
                      console.log(packet.content)
                  }
                  Socket.onTrade = packet =>{//收到对手的交易信息
                      console.log(packet)
                      that.$store.commit('pushtrade',packet.content.Trade)
                  }
                  Socket.onMessage = packet =>{
                      console.log(packet)
                      that.$store.commit('pushchat',{
                          nick:packet.content.nick,
                          msg:packet.content.msg
                      })
                  }
                  Socket.onChallenge = packet =>{//监听挑战信息
                      this.$confirm('有玩家向你发出挑战，是否接受挑战？', '挑战信息', {
                          confirmButtonText: '接受挑战',
                          cancelButtonText: '拒绝挑战',
                          type: 'warning'
                      }).then(() => {
                          Socket.emitMessage('accept',packet.content)
                      }).catch(() => {
                          Socket.emitMessage('decline',packet.content)       
                      });
                  }
                  Socket.onOffline = packet =>{//监听挑战对方不在线
                      this.$alert('挑战对象当前不在线', '挑战对方', {
                          confirmButtonText: '确定',
                      });
                  }
                  Socket.onDecline = packet =>{//监听对方拒绝了你的挑战
                      this.$alert('对方拒绝了你的挑战', '挑战对方', {
                          confirmButtonText: '确定',
                      });
                  }
                  Socket.onFinish = packet =>{//监听比赛完成
                      console.log(packet.content)
                      let room = packet.content.room
                      if(room.charAt(8) == 1){
                          that.$store.commit('finishmatch',packet.content.result)
                      }
                      if(room.charAt(8) == 2){//混战
                          that.$store.commit('finishmatch',packet.content.result)
                      }
                  }
                  Socket.onPrepare = packet =>{//监听收到比赛模式的准备
                      console.log('监听比赛收到的命令',packet)
                      sessionStorage.setItem('battlenumber','02004')
                      that.$router.push('/competition')
                      setTimeout(() =>{
                          that.$store.commit('setprepare',packet.content)
                      },1000)
                  }
              }
          })        
      }        
  }
  trylink()
}


import { Loading } from 'element-ui';
 
 
export class loading {
    constructor(loadOption){
      this.loadOption = loadOption ;  //vue中的this  也可以不用
    }
    open(){
        this.loadingInstance = Loading.service(this.loadOption);
    }
    close(){
        this.loadingInstance.close();
    }
}

import PublicApi from "@/api/public";
export function getBasicInfo (etype) {
    PublicApi.getBasicinfo(etype).then(res=>{
        if("stats" in res.data){
            var sts = {"text":0};
            var total = 0;
            for(var key in res.data.stats){
                if(!isNaN(res.data.stats[key]))
                    total += res.data.stats[key];

                if(key == "application" || key == "text")
                    sts.text += res.data.stats[key];
            }

            delete res.data.stats.application;
            res.data.stats.text = sts.text;
            res.data.stats.total = total;
        }

        /*下面三行等全部更换可以删除*/
        // this.$store.commit("setBasicInfo", res.data);
        // this.$store.commit("setRssUrl", res.data.domain);
        // sessionStorage.setItem("website",JSON.stringify(res.data));

        // console.log("getBasicinfo2=>",res.data);
        this.$store.commit("setLibInfo", [etype, res.data]);
        var slibInfo = sessionStorage.getItem("libInfo")??"";

        var libInfo = slibInfo.length>0?JSON.parse(slibInfo):{};
        libInfo[etype] = res.data;
        
        sessionStorage.setItem("libInfo",JSON.stringify(libInfo));
    }).catch(err=>{
        console.log("getBasicinfo=>",etype,err);
    });
  }