<template>
    <div class="header">
        <div>
            <div class="search">
                <el-select v-model="searchType" class="selectBox">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>

                <input v-model="searchValue" type="text" @keydown.enter="search" placeholder="请输入关键字搜索">
                <div @click="search" class="searchButton">
                    搜索
                </div>
            </div>
        </div>
        <i class="arrow fa fa-arrow-left" aria-hidden="true" @click="turnBack"></i>
        <i class="home fa fa-home" aria-hidden="true" @click="goHome"></i>
        <p class="login">
            <span @click="toStat()"><i class="fa fa-bar-chart" aria-hidden="true">统计</i><p></p></span>

            <!-- 
            <span v-if="!nickname" @click="doLogin"><i class="fa fa-user" aria-hidden="true"></i>登录<p></p></span>
            <span v-else @click="doLogout"><i class="fa fa-user" aria-hidden="true"></i>登出<p></p></span> 
            -->
        </p>
    </div>
</template>
<script>
import PublicApi from "@/api/public"
export default {
    data () {
        return {
            searchVisible: true,
            searchValue:'',
            searchType:"0",
            username:null,
            nickname:null,
            options: [{
                value: '0',
                label: '人物'
                }, {
                value: '1',
                label: '视频'
                }, {
                value: '20',
                label: '图集'
                }, {
                value: '3',
                label: '音频'
                }, {
                value: '4',
                label: '文本'
            }],
        }
    },
    methods:{
        turnBack () {
            this.$router.go(-1)
        },
        goHome () {
            this.$router.push('/')
        },
        search () {//搜索页
          if(this.searchValue.length>0)
            location.href = `/figure/search?type=${this.searchType}&content=${this.searchValue}`;
            // this.$router.replace({"path":"/figure/search" ,"query":{"type":this.searchType,"content":this.searchValue}})
          else
            alert("请输入查询关键字");
        },
        toStat(){
          this.$router.push('/figure/statistics')
        },
        doLogout () {
            PublicApi.apiLogout().then(() =>{
                // this.$router.push('/login')
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('mobile');
                this.nickname = null;
            })
        },
        doLogin(){
            this.$router.push("/login")
        },
        doVisible(type) {
            this.searchVisible = true;
            this.searchType = type;
        },   
    },
    mounted () {
        this.$nextTick(() =>{
            // this.username = sessionStorage.getItem('username')
            this.nickname = sessionStorage.getItem('mobile')
        })
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 1200px) {
  .header{
    >div{
      .search{

      }
    }
  }
}
.header{
  position: relative;
  background-image: url('@/assets/img/header-bg.gif');
  background-size: cover;
  background-position:10% 10%;
  background-repeat: no-repeat;
  height:250px;
  max-height: 299px;
  width: 100%;
  overflow: hidden;
  >div{
    max-width: 1200px;
    width: 100%;
    margin: 6rem auto 3rem;
    text-align: center;
    .search{
      display: flex;
      vertical-align: top;
      display: inline-block;
      height: 2.5rem;
      //border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.8);
      position: relative;
      //overflow: hidden;
      text-align: left;
      padding-right: 6rem;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      >.selectBox {
        left:-96px;
      }
      >div{
        position: absolute;
        right: 0;
        top: 0;
        height: 2.5rem;
        background-color: #245d5e;
        color: #fff;
        line-height: 2.5rem;
        font-size: 1.2rem;
        width: 6rem;
        text-align: center;
        cursor: pointer;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      input{
        margin-left: 10px;
        margin-right: 10px;
        display: inline-block;
        width: 20rem;
        line-height: 2.5rem;
        font-size: 1rem;
        border: none;
        background:none;
        outline: none;
        height:  2.5rem;
      }
    }
  }
  /deep/ .el-input__inner {
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  //去掉下拉边框
  /deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: white;
  }

  .arrow{
    position: absolute;
    color: #fff;
    top: 20px;
    left: 60px;
    font-size: 1.8rem;
    cursor: pointer;
  }
  .home{
    position: absolute;
    color: #fff;
    top: 20px;
    left: 20px;
    font-size: 2rem;
    cursor: pointer;
  }
  .login{
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 1.3rem;
    color: #fff;

    span{
      margin-left: 30px;
      display: inline-block;
      cursor: pointer;
      text-align: center;
      p{
        border-bottom: 1px solid #fff;
        width: 0;
        margin: 0.2rem auto;
        transition: all .3s ease;
      }
      &:hover{
        p{
          width: 100%;
        }
      }
    }
  }
}
</style>
