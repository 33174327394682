<template>
    <transition name="fade">
        <i v-show="show" :class="show?'show':''" class="turntop fa fa-chevron-up" aria-hidden="true" @click="turntop"></i>
    </transition>
</template>
<script>
export default {
    data () {
        return {
            show:false,
            timer:null,
            scrollTop:0
        }
    },
    mounted () {
        window.addEventListener('scroll', this.handleScroll)
    },
    methods:{
        handleScroll () {
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if(scrollTop > this.scrollTop && this.timer !== null){
                clearInterval(this.timer);
                this.timer = null
            }
            this.scrollTop = scrollTop
            if(scrollTop >= 100 && this.show == false){
                this.show = true
            }else if(scrollTop < 100 && this.show == true){
                this.show = false
            }
        },
        turntop () {
            //var dom = document.querySelector('body');
            //var h = dom.scrollTop;
            //var subH = parseInt(h / 50);
            clearInterval(this.timer);
            this.timer = setInterval(() =>{
                //获取滚动条的滚动高度
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                //用于设置速度差，产生缓动的效果
                var speed = Math.floor(-scrollTop / 8);
                document.documentElement.scrollTop = document.body.scrollTop = scrollTop + speed;//用纯数字赋值
                if(scrollTop <= 1){
                    clearInterval(this.timer);
                    this.timer = null
                }
            },20);  
        }
    }
}
</script>
<style lang="less" scoped>
.turntop{
    position: fixed;
    width: 2.5rem;
    height: 2.5rem;
    // right: 2.5rem;
    // bottom: 2.5rem;
    bottom:4.1rem;
    right:1rem;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255,0);
    color: rgb(37, 150, 135);
    background-color: rgba(255, 255, 255,0.4);
    cursor: pointer;
    text-align: center;
    line-height: 2.2rem;
    z-index: 8887;
    transition: all .5s ease;
    box-sizing: border-box;
    &:hover{
        background-color: rgb(37, 150, 135);
        color: #fff;
    }
}
.fade-enter-active, .fade-leave-active {
  transition: all .5s ease;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
  transform: translateY(2rem);
}
</style>

