<template>
  <div class="village_head flex-col">
    <div class="headsection_top flex-col">
         <div>
           <img href="#" src="@/assets/img/default.png"  @click="turnback" style="width:100%;height:100%"/>
         </div>
         <div>
           <a href="#"  @click="turnback"><div class="headsection_1 flex-col"></div></a>
         </div>
    </div>
    <div class="section_head flex-row">
      <div class="  flex-row  hvr-underline-from-center">
        <div style="height:25px;margin-right: 10px;" ><img src="@/assets/img/village/icon/icon_portal.png" style="height:25px;"></div>
        <div class="headtext_1" @click="goToPage(`/village/index/`)">村落首页</div>
      </div>
      <div class="  flex-row  hvr-underline-from-center">
        <div style="height:25px;margin-right: 10px;" ><img src="@/assets/img/village/icon/icon_vr.png" style="height:25px;"></div>
        <div class="headtext_1" @click="goToPage(`/village/indexvr/`)">VR专题馆</div>
      </div>

      <div class="  flex-row  hvr-underline-from-center">
        <div style="height:25px;margin-right: 10px;" ><img src="@/assets/img/village/icon/icon_liuyan.png" style="height:25px;"></div>
        <div class="headtext_1" @click="goToPage(`/village/message/`)">留言馆</div>
      </div>
    </div> 
  </div>
</template>

<script>
/**
 * 公共头部
 * 
 */ 

import villageSearch from "@/components/village/search.vue"
export default {
  name: "Head",
  components: {
    villageSearch
  },
  data () {
    return {
      activeIndex: "1",
      inpVal: "",
    };
  },
  methods: {
    turnback () {
      this.$router.push('/')
    },
    goToPage (path) {
      this.$router.push({ path });
    },
    // 搜索
    handleSearch () {
      console.log(this.inpVal)
    }
  },
};
</script>
<style lang="less" scoped> 

.village_head {
  position: relative; 
  align-items: center;
}
.section_head { 
  height: 38px;
  margin: 24px 0 24px 0px;
  z-index:5;
}
.headsection_top {
    position: absolute;
    width: 90px;
    height: 80px; 
    left: 180px;
    top:5px;
    display:flex;
    flex-direction: row;
}
.headsection_1 {
  width: 32px;
  height: 32px;
  background: url(@/assets/img/village/vr/SketchPng400f43e642752e78bf956e88a8bc52b1e58593af96afa072f227a4afd20a4a37.png)
    100% no-repeat;
  background-size: 100% 100%;
  margin-top: 24px; 
  margin-left: 20px;
}

.headtext_1 {
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-family: PingFangSC-Semibold;
  text-align: left;
  white-space: nowrap;
  line-height: 25px; 
  cursor:pointer;
}

.headtext_2 {
  width: 79px;
  height: 35px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-family: PingFangSC-Semibold;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 6px 0 0 6px;
}

.headtext_3 {
  width: 54px;
  height: 35px;
  overflow-wrap: break-word;
  color: rgba(155, 155, 155, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin: 6px 0 0 6px;
}


.headblock_2 {
  width: 18px;
  height: 16px; 
  
}

.headblock_3 {
  width: 19px;
  height: 16px;
  background:  url(@/assets/img/village/icon/icon_vr.png) -1px -1px
    no-repeat;
  background-size: 22px 19px;
  margin: 11px 0 0 67px;
}

.headblock_4 {
  width: 18px;
  height: 17px;
  background: url(@/assets/img/village/icon/icon_liuyan.png)  -1px -1px
    no-repeat;
  background-size: 20px 19px;
  margin: 11px 0 0 67px;
}
.group_xiahuaxian {
    background-color: #71a0bd;
    border-radius: 2px;
    width: 46px;
    height: 4px;
    margin: 35px 0 0 -63px;
}

.headsection_2 {
  width: 425px;
  height: 32px;
  margin-left: 141px;
}


.hvr-underline-from-left {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px transparent;
	position: relative;
	overflow: hidden
}

.hvr-underline-from-left:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 100%;
	bottom: 0;
	background: #2098d1;
	height: 4px;
	transition-property: right;
	transition-duration: .3s;
	transition-timing-function: ease-out
}

.hvr-underline-from-left:active:before,.hvr-underline-from-left:focus:before,.hvr-underline-from-left:hover:before {
	right: 0
}

.hvr-underline-from-center {
	display: flex;
  flex-direction: row;
  
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px transparent;
	position: relative;
	overflow: hidden;
  opacity: 0.5;
  margin-right: 100px;
}

.hvr-underline-from-center:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 51%;
	right: 51%;
	bottom: 0;
	background: #2098d1;
	height: 4px;
	transition-property: left,right;
	transition-duration: .3s;
	transition-timing-function: ease-out;
}

.hvr-underline-from-center:hover{

         opacity: 2;
         color: black;

}
.hvr-underline-from-center:active:before,.hvr-underline-from-center:focus:before,.hvr-underline-from-center:hover:before {
	left: 0;
	right: 0
}

.hvr-underline-from-right {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px transparent;
	position: relative;
	overflow: hidden
}

.hvr-underline-from-right:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 100%;
	right: 0;
	bottom: 0;
	background: #2098d1;
	height: 4px;
	transition-property: left;
	transition-duration: .3s;
	transition-timing-function: ease-out
}

.hvr-underline-from-right:active:before,.hvr-underline-from-right:focus:before,.hvr-underline-from-right:hover:before {
	left: 0
}
</style>
