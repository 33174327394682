import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload,{
  loading: require('@/assets/img/yearbook.png'), // 图片加载时默认图片
  error: require('@/assets/img/404.png')// 图片加载失败时默认图片
})

import ElementUI from 'element-ui'
Vue.use(ElementUI)

import HappyScroll from 'vue-happy-scroll'
Vue.use(HappyScroll)

import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';
Vue.prototype.$layer = layer(Vue);

import "swiper/css";
import 'font-awesome/css/font-awesome.min.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';
import 'vue-happy-scroll/docs/happy-scroll.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
 
//图片预览
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    inline: false,
    button: false, //右上角按钮
    navbar: false, //底部缩略图
    title: false, //当前图片标题
    toolbar: true, //底部工具栏
    tooltip: true, //显示缩放百分比
    movable: true, //是否可以移动
    zoomable: true, //是否可以缩放
    rotatable: true, //是否可旋转
    scalable: true, //是否可翻转
    transition: false, //使用 CSS3 过度
    fullscreen: false, //播放时是否全屏
    keyboard: true, //是否支持键盘
    url: "data-source",
  },
});

/*redtour 3D map*/
import * as echarts from 'echarts';
import 'echarts-gl'
Vue.prototype.$echarts = echarts

import { IsPC, getBasicInfo } from '@/utils'
Vue.prototype.isPc = IsPC();
Vue.prototype.$getBasicInfo = getBasicInfo;

Vue.prototype.$imgerror = 'this.src="' + require('@/assets/img/default.png') + '"'

// import logger from './utils/logger.js';
// Vue.prototype.$log = logger.getLogger();

Vue.config.productionTip = false

new Vue({
  store,
  router,  
  render: h => h(App),
}).$mount('#app')
