<template>
  <div class="m-select-wrap">
    <input
      :class="['u-select-input f16', color === 'blue' ? '' : 'white-color']"
      type="text"
      readonly
      @click="openSelect"
      @blur="onBlur"
      v-model="selectName" />
    <div :class="['triangle-down', { 'rotate': rotate }]" @click="openSelect"></div>
    <div :class="['m-options-panel f16', showOptions ? 'show': 'hidden']" :style="`height: ${selectData.length * 32}px;`">
      <p class="u-option" @mousedown="getValue(item.name, item.value, index)" v-for="(item, index) in selectData" :key="index">
        {{ item.name }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Select',
  props: {
    selectData: {
      type: Array,
      default: () => {
        return []
      }
    },
    // eslint-disable-next-line vue/require-prop-types
    selValue: { // 将该prop值作为selV的初始值
      default: undefined
    },
    color: {
      type: String,
      default: () => {
        return 'blue'
      }
    }
  },
  computed: {
    selectName () {
      let selName
      this.selectData.forEach(item => {
        if (item.value === this.selectValue) {
          selName = item.name
        }
      })
      return selName
    },
    selectValue: {
      get () {
        return this.selV
      },
      set (newVal) {
        this.selV = newVal
      }
    }
  },
  data () {
    return {
      selV: this.selValue,
      rotate: false,
      showOptions: false
    }
  },
  methods: {
    openSelect () {
      this.showOptions = !this.showOptions
      this.rotate = !this.rotate
    },
    getValue (name, value, index) {
      this.selectValue = value
      this.$emit('getValue', name, value, index)
      this.showOptions = false
      this.rotate = false
    },
    onBlur () {
      this.showOptions = false
      this.rotate = false
    }
  }
}
</script>
<style lang="less" scoped>
.m-select-wrap {
  width: 105px;
  height: 40px;
  left:20px;
  line-height: 32px;
  position: relative;
  border: none;
  .u-select-input {
    width: 105px; 
    color: #FFFFFF; 
    border-radius: 20px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    border: 0px;
  }
  .white-color { 
    color: #000000;
  }
  .triangle-down { // 下三角样式
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #333;
    position: absolute;
    top: 11px;
    right: 30px;
    transition: transform 0.3s ease-in-out;
  }
  .rotate {
    transform: rotate(180deg);
  }
  .m-options-panel {
    position: absolute;
    background: #FFFFFF;
    border-radius: 8px;
    width: 133px;
   // border: 1px solid #E3E3E3;
    /*top: 46px;*/
    top: 40px;
    left: 0;
    margin-left: -6px;
    color: #706F94;
    .u-option {
      padding: 0 15px;
      cursor: pointer;
    }
    .u-option:hover {
      color: #3A79EE;
      background: #EEF1FA;
      border-radius: 8px;
    }
  }
  .show {
    display: block;
  }
  .hidden {
    display: none;
  }
}
</style>