<template>
  <div id="app">
    <router-view name="header"/>
    <div :style="minheight">
    <keep-alive><router-view :key="$route.fullPath" v-if="$route.meta?.keepAlive"/></keep-alive>
    <router-view :key="$route.fullPath"  v-if="!$route.meta?.keepAlive"/>
    </div>
    <router-view name="footer"/>
    <ZkTurntop></ZkTurntop>
  </div>
</template>

<script>
import ZkTurntop from '@/components/ZkTurntop.vue'
import {loading} from '@/utils';
import PublicApi from '@/api/public';

export default {
  name: 'App',
  components: {
    ZkTurntop
  },
  data(){
    return {
      stsTimer:null,
      minheight: "min-height: calc(120vh - 171px);",
    }    
  },
  methods: {
    writeStatMap(){
      var stsmap = [];
      var simTbl = [];
      this.$router.options.routes.forEach(e => {
        if(e.hasOwnProperty('meta')){
          stsmap.push({"path":e.path,"name":e.name,"meta":e.meta});
          simTbl.push({"title":e.meta.title,"path":e.path,"sn":e.meta.index})
        }
      });
      /*1、export the route style of simulator for python use*/
      console.log(simTbl);

      /*2、export the js content for uniapp use */
      console.log(`export default {routerData:${JSON.stringify(stsmap)}}`);
    },
    setPVUV(){
      var sts = localStorage.getItem("stats");
      if(!sts || sts.length < 2)
        return;

      try {
        var stats = JSON.parse(sts);
        var stslist = [];
        for(var key in stats){
          let stat = stats[key];
          stat.uri = key;
          stslist.push(stat);
        }
        console.log("setPVUV sts=>",stslist);
        PublicApi.apiStatUpdate(stslist).then(res=>{
          if(res.code==0){
            localStorage.removeItem("stats");
          }
        }).catch(error=>{
            console.log("apiStatUpdate error==>",error);
        })
      } catch (error2) {
        localStorage.removeItem("stats");
        console.log("apiStatUpdate exception==>", error2);
      }
    }
  },
  mounted () {
    const loadOption= {fullscreen: true ,lock: true, text: '数据加载中...', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'};
    window.loading = new loading(loadOption);

    /*FOR OUTPUT USE，Need to comment in the build */
    // this.writeStatMap();
    this.stsTimer = setInterval(()=>{
      this.setPVUV();
    }, 300000);
  },
  destroyed(){
    this.setPVUV();
    clearInterval(this.stsTimer);
  }
}
</script>

<style lang="less">
*{
  margin: 0;
  padding: 0;
}

body{
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (min-width: 1920px){
    html{
      font-size:16px;
    }
}
@media screen and (max-width: 1920px){
    html{
      font-size:16px;
    }
}
@media screen and (max-width: 1200px){
    html{
      font-size:15px;
    }
}
@media screen and (max-width: 992px){
    html{
      font-size:14px;
    }
}
@media screen and (max-width: 768px){
    html{
      font-size:12px;
    }
}

.font-24{
  font-size: 24px;
}
//element树形控件样式修改
.el-tree-node__label{
  font-size: 16px !important;
}
.el-tree-node__expand-icon{
  font-size: 16px !important;
}
.el-tree-node__children{
  .el-tree-node__label{
    color: rgb(24, 105, 105) !important;
    font-weight: bold;
  }
}

* {
    padding: 0;
    margin: 0;
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    -webkit-touch-callout: none;
    user-select: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.back{
    position: absolute;
    z-index: 20;
    top:0;
    left: 0;
    display: block;
    background: rgba(#245e5f, 0.5);
    width:2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img{
        width:2rem;
    }
}
.el-popover {
    min-width: 70px !important;
    padding: 0 !important;
    .search-item {
        > div {
            cursor: pointer;
            padding: 5px 10px;
            &:hover {
                background: rgba(#245e5f, 0.5);
                color: #fff;
            }
        }
    }
}
.el-calendar{
    .el-calendar-day{
        height:2.8rem!important;
        .is-today{
            color:#245e5f!important;
        }
    }
    .el-calendar__button-group{
        .el-button--mini, .el-button--mini.is-round{
            padding:3px 5px!important;
        }
    }
    .el-calendar__body{
        padding:5px 20px !important;
        thead{
            th{
                padding:0 !important;
            }
            
        }
    }
}
</style>
