<template>
  <div class='main'>
    <div  class='section_search'>
      <div class='search_arr'>
          <Select
          :selectData="selectData"
          :selValue="selValue"
          color="white"
          @getValue="getValue" /> 
        <el-input style="width:55%" v-model="value" autofocus="true"  placeholder="请输入搜索关键字" @input='bindkeyinput' v-on:keyup.enter.native="search"/>
        <img  class="cancel" src="@/assets/img/village/top_btn_serch.png" @click="handleClear" />
      </div>
    </div>
    
  </div>
</template>

<script>
import Select from '@/components/select.vue'
import PublicApi from '@/api/public'
export default {
  components: {
    Select  
  },
  props:{
    placeholder:String,
    totalArr:Array,
  },
  data(){
    return {
      selectData: [
          {
              name: '村落',
              value: 0
          },
          {
              name: '视频',
              value: 1
          }, 
          {
              name: '图集',
              value: 20
          },
          {
              name: '音频',
              value: 3
          },
          {
              name: '文本',
              value: 4
          },
        ],
        searchQuery:{
          content:'',
          category:'',
          mediatype:'0',
        },
        selValue: '',
        value:'',
        resList:[],
        clientHeight:null
    }
  },
  created () {
    // 初始化下拉框
    this.selValue = this.selectData[0].value
},
  mounted() {
    window.onresize = () => {
      return (() => {
        console.log('clientHeight:',document.documentElement.clientHeight)
        this.clientHeight = document.documentElement.clientHeight;//这里需要注意一下可视区高度。

        })();
      }
  },
  computed:{
    sectionHeight(){
      return this.clientHeight - 60 - 50
    }
  },
  methods:{
    getValue (name, value, index) {
      console.log('item from parent :', name, value, index)
      this.searchQuery.mediatype = value
    },
    //文本框输入事件
    bindkeyinput(e) {
      console.log(e);
      let value = e;
      this.value = e;
      this.searchQuery.content = e
      
    },
    search(e){
      console.log(e);
      if(e.keyCode==13){ 
         this.searchQuery['category']=1
         localStorage.setItem('villageSearchType',this.searchQuery.mediatype)
         localStorage.setItem('villageSearchResult',[])
         this.$emit('search', this.searchQuery)
      }
    },
    handleClear(){
      this.value = '';
      this.resList = [];
    },
    chooseItem(e){
      console.log(e)
      let id = e.id;
      let name = e.name.join("");
      this.value = name;
      this.resList = [];
      this.$emit('changeValue',{name,id});
    },
  }
}
</script>

<style lang="scss">
.main{
  z-index: 10000;
  width:600px;
}
.section_search{
  display: flex;
  padding: 6px 0;
}

:focus-visible {
  outline: none;
}
.search_arr{ 
    border-radius: 15px;
    display: flex;
    background: white;
    position: relative;
    height: 32px;
    border: 1px solid gray;
    position: relative;
    height: 32px;
    .cancel,.searchcion{
      width: 24px;
      height: 24px;
    }
 
}
.searchcion{
  position: absolute;
  left: 12px;
  top:4px;
  z-index: 2;
}

.search_arr .el-input input { 
  height: 32px; 
  font-size: 14px;
  color: #323439;
  line-height: 20px;
  border: none;
  background: none;
}

.cancel {
  position: absolute;
  right: 12px;
  top: 4px;
}

.section_show {
  padding-top: 5px;
  width: 100%;
}
.section_show .res_item{
  padding: 15px 0;
  border-bottom: 1px solid #F7F7F7;
}
.section_show .res_item>.item_name{
  color: #323439;
  font-size: 15px;
  line-height: 21px;
  margin-bottom:2px;
}
.section_show .res_item>.item_id{
  color: #999CA3;
  font-size: 12px;
  line-height: 17px;
}
.highLight{
  color: #F45350;
}
</style> 