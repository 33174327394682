<template>
  <div class="head">
    <div class="head__content">
      <div>
      <a href="https://www.nslib.cn"><img class="head__img" src="@/assets/img/openyear/nantulogo.png"  alt="南山图书馆" /></a>
      <img class="head__img" src="@/assets/img/openyear/annuallogo.png" alt="25周年展" />
      </div>
      <div class="head__nav" v-if="this.isPc">
        <el-menu :default-active="this.$route.meta.navIndex" mode="horizontal">
          <el-menu-item index="1" @click="goToPage('/openyear/index')">
            <template slot="title">
              <img class="head__nav--icon" src="@/assets/img/openyear/head_home.png" />
              <span>首页</span>
            </template>
          </el-menu-item>
          <el-menu-item index="2" @click="goToPage(`/openyear/vr/${basicInfo.vrindex}`)">
            <template slot="title">
              <img class="head__nav--icon" src="@/assets/img/openyear/head_vr.png" />
              <span>云展馆</span>
            </template>
          </el-menu-item>
          <el-menu-item index="3" @click="goToPage('/openyear/message')">
            <template slot="title">
              <img class="head__nav--icon" src="@/assets/img/openyear/message.png" />
              <span>留言墙</span>
            </template>
          </el-menu-item>
        </el-menu>
      </div>
      <div v-else class="d">
          <div class="orgname">
              <h2>南山图书馆25周年</h2>
              <span></span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 公共头部
 * 
 */
export default {
  name: "Head",
  components: {},
  data () {
    return {
      activeIndex: "1",
      inpVal: "",
      basicInfo: this.$store.state.libInfo.openyear??{},
    };
  },
  methods: {
    goToPage (path) {
      this.$router.push({ path });
    },
    // 搜索
    handleSearch () {
      console.log(this.inpVal)
    }
  },
  mounted(){
    if(this.basicInfo.length<1){
        this.$getBasicInfo("openyear");
    }
  }
};
</script>
<style lang="scss" scoped>
.head {
  font-size: 12px;
  width: 100%;
  height: 72px;
  display: flex;
  // background: rgba($color: #000000, $alpha: 0.6);
  background: rgba($color: #E77C2C, $alpha: 1.0);
  &__img {
    width: 60px;
    margin-top:-10px;
    padding: 10px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
  }
  &__nav {
    display: flex;
    align-items: center;
    position: relative;
    .search {
      input {
        width: 165px;
        height: 26px;
        background: unset;
        border: 1px solid #fff;
        border-radius: 20px;
        outline: none;
        padding-left: 10px;
        box-sizing: border-box;
        color: #fff;
      }
      .head__search {
        position: absolute;
        right: 5px;
        top: 25px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &--icon {
      margin-right: 3px;
      width:20px;
      height:20px;
    }
  }
  &__search {
    position: absolute;
    right: 5px;
  }
  /deep/.el-menu {
    background-color: unset;
    &-item {
      font-size: 18px;
      height: 68px;
      line-height: 60px;
      padding: 0 10px;
      color: #fff;
      &:hover {
        background: rgba($color: #fff, $alpha: 0.3);
        color: #fff;
      }
    }
  }
  /deep/.el-menu.el-menu--horizontal {
    border: 0;
  }
  /deep/.el-menu--horizontal > .el-menu-item.is-active {
    background: rgba($color: #fff, $alpha: 0.3);
    color: #fff;
    border-bottom: 2px solid #ff0000;
  }
  /deep/.el-menu-item:focus,
  .el-menu-item:hover {
    background: unset;
  }
  .orgname {
    font-size: 2.4rem;
    display: block;
    height: auto;
    width: auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #E8B751;
    -webkit-text-stroke: 0.5px #fff;
    margin: 0 1rem;
    &  span{
      display: block;
      width: 100%;
      height:1.5rem;
      background:#C76607;
      margin-top:-1.5rem;
    }
  }

}
@media screen and (max-width: 888px) {
  .head__content {
    justify-content: center;
  }
}
</style>