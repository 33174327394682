<template>
  <div class="footer">
    <div class="footer__content">
      <!-- left -->
      <img
        class="head__nav--icon left"
        :src="`${rssUrl}${basicInfo.logo}`"
        :onerror="$imgerror"
      />
      <!-- center -->
      <div class="footer__center">
        <img class="head__nav--icon" src="@/assets/img/nstsg_logo.png" />
        <p>
          <img class="head__nav--icon" src="@/assets/img/redtour/footer_phone.png" />
          &nbsp;{{basicInfo.phone}}
        </p>
        <p class="footer__center--location">
          <img class="head__nav--icon" src="@/assets/img/redtour/footer_location.png" />
          &nbsp;{{basicInfo.address}}
        </p>
      </div>
      <!-- right -->
      <div class="footer__right">
        <p>关注我们</p>
        <div class="footer__wxandwb">
          <div class="footer__wx">
            <img :src="`${rssUrl}${basicInfo.wechat}`" :onerror="$imgerror" />
            <p>官方微信</p>
            <p>二维码</p>
          </div>
          <div class="footer__wx">
            <img :src="`${rssUrl}${basicInfo.weibo}`" :onerror="$imgerror" />
            <p>微博</p>
            <p>二维码</p>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <p>南山图书馆 Copyright© 2021 粤ICP备05054455号</p>
    </div>
  </div>
</template>

<script>
/**
 * 公共尾部
 * 
 */
export default {
  name: "Footer",
  components: {},
  data () {
    return {
    }
  },
  computed: {
    rssUrl() { return this.$store.state.rssUrl },
    basicInfo() { return this.$store.state.libInfo.redtour??{} },
  },  
  mounted () { 
    if(this.basicInfo.length<1){
        this.$getBasicInfo("redtour");
    }
    console.log("Footer basicInfo==>",this.basicInfo);
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/mixin.scss";
.footer {
  height: 234px;
  background: rgba($color: #161515, $alpha: 0.85);
  &__content {
    width: 1110px;
    // height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .head__nav--icon {
      &.left {
        width: 177px;
        height: 113px;
      }
    }
  }
  &__center {
    font-size: 18px;
    width: 624px;
    height: 190px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    margin: 0 50px;
    color: #ffffff;
    & > p {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      img {
        margin-right: 10px;
      }
    }
    &--location {
      font-size: 14px;
    }
  }
  &__right {
    font-size: 14px;
    color: #ffffff;
  }
  &__wxandwb {
    font-size: 12px;
    width: 180px;
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    img {
      width: 84px;
      height: 84px;
    }
  }
  &__wx {
    & > p {
      line-height: 24px;
    }
  }
}
.foot {
  background: rgba($color: #161515, $alpha: 0.95);
  color: #cccccc;
  height: 38px;
  font-size: 14px;
  margin-top: -10px;
  @include display_center;
}
</style>