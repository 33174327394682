<template>
  <div class="footer5">
    <div class="contF">
      <div class="allF">
        <img src="@/assets/img/nstsg_logo2.png" class="imgF" alt="" />
        <div class="footT">
          <div>
            <div>
              <span class="span">电话：0755-26520380</span>
              <span class="span">地址：深圳南山区常兴路176路</span>
            </div>
            <div>
              <span class="span">南山图书馆 Copyright © 2022 </span>
              <span class="span">粤ICP 备 05054455号</span>
            </div>
          </div>
          <div class="line"></div>
          <div>
            <div style="text-align: left">关注我们</div>
            <div class="flexF">
              <img src="@/assets/img/culture/phone.png" class="iconF" alt="" />
              <div class="block_6 flex-col">
                <p class="weixin-img">
                  <img
                    src="@/assets/img/weixin-erweima.png"
                    width="120px"
                    height="120px"
                  />
                </p>
              </div>
              <div class="block_7 flex-col">
                <p>
                  <img
                    class="weibo-img"
                    src="@/assets/img/weibo-erweima.png"
                    width="120px"
                    height="120px"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
/**
 * 公共头部
 *
 */
export default {
  name: "foot",
  components: {},
  data() {
    return {
      activeIndex: "1",
      inpVal: "",
    };
  },
  methods: {
    goToPage(path) {
      this.$router.push({ path });
    },
    // 搜索
    handleSearch() {
      console.log(this.inpVal);
    },
  },
};
</script>
  <style lang="scss" scoped>
.block_6 {
  width: 1.3vw;
  height: 1.3vw;
  margin-right: 2vw;
  background: url("@/assets/img/icon2.png") 0px -1px no-repeat;
  background-size: 1.3vw 1.3vw;
}
.weixin-img {
  display: none;
  margin-top: -150px;
  margin-left: -50px;
}
.block_6:hover .weixin-img {
  display: block;
}
.block_7 {
  width: 1.3vw;
  height: 1.3vw;
  margin-right: 2vw;
  background: url("@/assets/img/weibo2.png") 0px -1px no-repeat;
  background-size: 1.3vw 1.3vw;

}
.weibo-img {
  display: none;
  margin-top: -150px;
  margin-left: -50px;
}
.block_7:hover .weibo-img {
  display: block;
}
.footer5 {
  // background: #ffffff;
  .contF {
    width: 100%;
    height: 17.3vw;
    background: url("@/assets/img/culture/footers.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    .allF {
      width: 50vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .imgF {
        width: 15vw;
      }
      .footT {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 2vw;
        .span {
          margin-left: 1.56vw;
        }
        .line {
          width: 1px;
          height: 2.6vw;
          background: #545454;
          margin: 0 2.6vw;
        }
        .flexF {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .iconF {
            width: 1.3vw;
            height: 1.3vw;
            margin-right: 2vw;
          }
        }
      }
    }
  }
}
</style>