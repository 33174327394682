<template> 
<div>
<div class="image-wrapper_7 flex-row">
    <img
      class="image_1"
      referrerpolicy="no-referrer"
      src="@/assets/img/village/SketchPngb95183bce8c4d3884f50f2b7fac1f960d9f05f67cdb4b580d0bdc8b78b273c83.png" 
    />
  </div>
  <div class="box_143 flex-row">
    <img
      class="image_2"
      referrerpolicy="no-referrer"
      src="@/assets/img/village/SketchPng2dbf37b1bb9d3cf82e00cd49c821c4baac22b3189c591283173778eeb28e7455.png"
    />
    <span class="paragraph_1"
      >电话：&nbsp;0755-26520380<br />地址：&nbsp;深圳南山区常兴路176号</span
    >
    <div class="box_7 flex-col"></div>
    <div class="box_144 flex-col justify-between">
      <span class="text_14">关注我们</span>
      <div class="box_9 flex-col"></div>
    </div>
    <div class="box_10 flex-col"></div>
    <div class="box_11 flex-col"></div>
  </div>
  <div class="text-wrapper_40 flex-row">
    <span class="text_15"
      >南山图书馆&nbsp;Copyright&nbsp;©&nbsp;2022&nbsp;粤ICP&nbsp;备&nbsp;05054455号</span
    >
  </div>
  <div class="group_41 flex-col"></div>
   
</div>
  </template>
  
  <script>
  /**
   * 公共头部
   * 
   */ 
  export default {
    name: "Head",
    components: {
       
    },
    data () {
      return {
        activeIndex: "1",
        inpVal: "",
      };
    },
    methods: {
      goToPage (path) {
        this.$router.push({ path });
      },
      // 搜索
      handleSearch () {
        console.log(this.inpVal)
      }
    },
  };
  </script>
  <style lang="scss" scoped> 
    @import '@/assets/css/common.css';  
   
   
  </style>