<template>
    <div class="header">
        <img class="logo" src="@/assets/img/corridor/corridor.png" alt @click="goHome"/>
        <div class="nav hidden-xs-only">
            <div
                class="item"
                v-for="(item, i) in circles"
                :key="item._id.$oid"
                    @mouseenter="mouseenter"
                    @mouseleave="mouseleave"
                    :data-idx="i"
            >
                <span @click="doCircle(item._id.$oid)">
                    <h4>{{ item.name }}</h4>
                    <p :style=" idx == i ? 'width:3rem' : 'width:0;'"></p>
                </span>
                <div v-if="idx==i&&item.organizations.length>0" class="list" >
                    <div
                        class v-for="(org, o) in item.organizations"
                        :key="o"
                        @mouseover="mouseoverItem(org._id.$oid)"
                        @click="doDetail(org._id.$oid)"
                    >
                        <span>
                            <p v-if="org._id.$oid == id"></p>
                        </span>
                        <h5 :title="org.name">{{ org.name }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    props: ["circles","logo"],
    data() {
        return {
            idx: -1,
            show: false,

            left: 0,
            id: "",
            list: [],
        };
    },
    methods: {
        goHome(){
            this.$router.push("/corridor/index");
        },
        doCircle(id){
            console.log('doCircle:',id)
            this.$emit('doCircle==>',id)
        },
        doDetail(id) {
            this.$router.push({path:'/corridor/organization',query:{id:id}})
        },
        mouseenter(e) {
            const idx = e.target.dataset.idx;
            this.left = e.target.offsetLeft;
            this.idx = idx;
            this.list = this.circles[idx].organizations;
            this.show = true;
        },
        mouseleave() {
            this.idx = -1;
            this.name = "";
            this.show = false;
        },
        mouseoverItem(id) {
            this.id = id;
        },
        mouseleaveItem() {
            this.id = "";
        },
    },
    created() {},
    mounted() {},
    beforeDestroy() {},
};
</script>

<style lang='less' scope>
@import '@/assets/css/corridor.css';

.header {
    width: 100vw;
    // height: 12.5rem;
    background: rgba(37, 100, 114, 0.6);
    height: 7.5rem;
    color: #fff;
    position: relative;
    .logo {
        height: 6.5rem;
        position: absolute;
        top:0.5rem;
        left:1rem;
    }
    .nav {
        max-width: 75rem;
        width:100vw;
        margin: 0 auto;
        position: relative;
        top:5rem;
        .item {
            float: left;
            width: 12.5rem;
            display: flex;
            flex-direction: column;
            > span {
                display: block;
                width: 12.5rem;
                height:2.5rem;
                text-align: center;
                cursor: pointer;
                h4 {
                    font-weight: normal;
                    font-size: 1rem;
                    margin-bottom: 1rem;
                }
                p {
                    display: block;
                    height: 0.3rem;
                    margin: 0 auto;
                    background: #fff;
                    transition: all 0.3s ease;
                }
            }
            .list {
                background: rgba(37, 100, 114, 0.4);
                border-radius: 0 0.625rem;
                border: 1px solid #fff;
                box-sizing: border-box;
                color: #fff;
                width:12.5rem;
                transition: all 0.3s ease;
                > div {
                    width: 12.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    span {
                        display: block;
                        min-width: 1.875rem;
                        
                        p {
                            display: block;
                            padding: 0;
                            margin: 0;
                            width: 0;
                            height: 0;
                            border-top: 0.5rem solid transparent;
                            border-left: 0.75rem solid #fff;
                            border-bottom: 0.5rem solid transparent;
                        }
                    }
                    h5{
                        font-weight: 300;
                        font-size: 1rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
    .sel {
        color: #c6342e;
        border-bottom: 2px solid #c6342e;
    }
}
</style>