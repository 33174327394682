
<template>
    <div class="footer">
        <div>
            <p>
                <span><i class="fa fa-phone"></i></span>
                电话：0755-26520380
                </p>
            <p>
                <span><i class="fa fa-map-marker"></i></span>
                地址：深圳南山区常兴路176号
            </p>
        </div>
        <p>南山图书馆 Copyright (C) 2016 粤ICP备 05054455 号</p>
        <img src="@/assets/img/corridor/organization/footer_line.png" alt="">
    </div>
</template>

<script>
export default {
    name: "Footer",
    props: [],
    data() {
        return {
            
        };
    },
    methods: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
};
</script>

<style lang='less' scope>
@import '@/assets/css/corridor.css';

.footer {
    width: 100%;
    min-height: 17.5rem;
    background: rgba(#245e5f, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    >div{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        p{
            margin:0 1rem;
            display: flex;
            align-items: center;
            span{
                display: block;
                width: 1.5rem;
                height: 1.5rem;
                text-align: center;
                line-height: 1.5rem;
                border-radius: 50%;
                color: #245e5f;
                background: rgba(255, 255, 255, 0.3);
                margin-right:0.5rem;
            }
        }
    }
    >p{
        margin:1rem 0;
    }
    
}
</style>