import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    search:0,
    basicInfo: JSON.parse(sessionStorage.getItem("website")),
    rssUrl: 'https://rss.nslib.cn',//静态文件地址  
    libInfo: JSON.parse(sessionStorage.getItem("libInfo")) ?? {},
    stsMap:{},
  },
  mutations: {
    editsearch (state) {
      state.search++
    },
    setBasicInfo (state, payload) {
      state.basicInfo = payload
    },
    setRssUrl (state, payload) {
      state.rssUrl = payload
    },
    setStsMap (state, payload) {
      state.stsMap = payload
    },
    setLibInfo(state,[type,payload]){
      payload.timestamp = new Date();
      state.libInfo[type] = payload;

      state.rssUrl = payload.domain;
    }
  },
  actions: {
  },
  modules: {
  }
})
export default store