<template>
  <div class="head">
    <div class="head__content">
      <div style="display: flex;    flex-direction: row;    justify-content: space-between;    width: auto;">
        <img class="head__img" src="@/assets/img/yearbook.png" alt @click="goHome"/>
        <img class="head__logo" src="@/assets/img/redtour/head_title.png" alt @click="goRedtour"/>
       
      </div>
      
      <div class="head__nav" v-if="this.isPc">
        <el-menu :default-active="this.$route.meta.navIndex" mode="horizontal">
          <el-menu-item index="1" @click="goToPage('/redtour/index')">
            <template slot="title">
              <img class="head__nav--icon" src="@/assets/img/redtour/head_home.png" />
              <span>首页</span>
            </template>
          </el-menu-item>
          <el-menu-item index="2" @click="goToPage('/redtour/history')">
            <template slot="title">
              <img class="head__nav--icon" src="@/assets/img/redtour/head_history.png" />
              <span>党史百年</span>
            </template>
          </el-menu-item>
          <el-menu-item index="3" @click="goToPage('/redtour/list')">
            <template slot="title">
              <img class="head__nav--icon" src="@/assets/img/redtour/head_vr.png" />
              <span>VR全景</span>
            </template>
          </el-menu-item>
        </el-menu>

        <div class="redtourstat"  @click="toStat()">
            <!-- <input v-model="inpVal" />
            <img class="head__search" @click="handleSearch" src="@/assets/img/redtour/search.png" /> -->
             <i class="fa fa-bar-chart" aria-hidden="true">统计</i><p></p> 
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 公共头部
 * 
 */
export default {
  name: "Head",
  components: {},
  data () {
    return {
      activeIndex: "1",
      // input3: "",
      inpVal: "",
    };
  },
  methods: {
    goToPage (path) {
      this.$router.push({ path });
    },
    
    toStat(){
        this.$router.push('/redtour/statistics')
      },
    // 搜索
    handleSearch () {
      console.log(this.inpVal)
    },
    goHome(){
      this.$router.push("/");
    },
    goRedtour(){
      this.$router.push("/redtour/index");
    }
  },
};
</script>
<style lang="scss" scoped>
.head {
  font-size: 18px;
  width: 100%;
  height: 68px;
  display: flex;
  background: rgba($color: #000000, $alpha: 0.6);
  &__img {
    height: 68px;
  }
  &__logo {
    left:0px;
    height: 68px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
  }
  &__nav {
    display: flex;
    align-items: center;
    position: relative;

    .stat{
      margin-left: 100px;
      color: #fff;
    }
    .search {
      input {
        width: 165px;
        height: 26px;
        background: unset;
        border: 1px solid #fff;
        border-radius: 20px;
        outline: none;
        padding-left: 10px;
        box-sizing: border-box;
        color: #fff;
      }
      .head__search {
        position: absolute;
        right: 5px;
        top: 25px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    &--icon {
      margin-right: 3px;
    }
  }
  &__search {
    position: absolute;
    right: 5px;
  }
  /deep/.el-menu {
    background-color: unset;
    &-item {
      font-size: 18px;
      height: 68px;
      line-height: 60px;
      padding: 0 10px;
      color: #fff;
      &:hover {
        background: rgba($color: #fff, $alpha: 0.3);
        color: #fff;
      }
    }
  }
  /deep/.el-menu.el-menu--horizontal {
    border: 0;
  }
  /deep/.el-menu--horizontal > .el-menu-item.is-active {
    background: rgba($color: #fff, $alpha: 0.3);
    color: #fff;
    border-bottom: 2px solid #ff0000;
  }
  /deep/.el-menu-item:focus,
  .el-menu-item:hover {
    background: unset;
  }
}
@media screen and (max-width: 888px) {
  .head__content {
    justify-content: center;
  }
}
.redtourstat{
    font-size: 18px;
    height: 68px;
    line-height: 60px;
    padding: 0 10px;
    color: #fff;
    cursor:pointer;
}
</style>