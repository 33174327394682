<template>
  <div class="topBox">
    <div class="searchBox">
      <div class="flex-center">
        <div>
          <img src="@/assets/img/culture/logo.png" class="logo" alt="" @click="toHome" />
          <img src="@/assets/img/culture/return2.png" class="return" alt="" @click="toReturn" />
        </div>
        <div class="allBox">
          <div class="search">
            <div class="sleft">
              <div class="searchimg" @click="showSelect = !showSelect">
                <div>{{ selectData[selValue].name }}</div>
                <img src="@/assets/img/culture/bottom2.png" alt="" />
              </div>
              <input type="text" class="input" placeholder="请输入您想搜索的内容" v-model="search" @keyup.enter="toSearch" />
            </div>
            <div class="sright" @click="toSearch">搜索</div>
          </div>
          <div class="selects" v-show="showSelect">
            <div v-for="(item, index) in selectData" :class="index == selValue ? 'selectA' : 'select'"
              @click="changeSel(index)">{{
                item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 公共头部
 *
 */

export default {
  name: "Head",
  data() {
    return {
      search: "",
      selectData: [
        {
          name: '文旅',
          value: 0
        },
        {
          name: '视频',
          value: 1
        },
        {
          name: '图集',
          value: 20
        },
        {
          name: '音频',
          value: 3
        },
        {
          name: '文本',
          value: 4
        },
      ],
      selValue: 0,
      showSelect: false,
    };
  },
  mounted() { },
  methods: {
    changeSel(index) {
      this.selValue = index
      this.showSelect = false
    },
    toHome() {
      this.$router.push("/");
    },
    toReturn() {
      this.$router.go(-1);
    },
    toSearch() {
      this.$router.push("/culture/search?type="+this.selectData[this.selValue].value+"&search=" + this.search);

    },
  },
};
</script>
<style lang="less" scoped>
.topBox {
  width: 100%;
  background: white;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.1);

  .searchBox {
    width: 100%;

    .flex-center {
      margin: 0 15vw;
      padding: 0.5vw;
      width: 70%;
      display: flex;
      align-content: center;
      justify-content: space-between;

      .logo {
        width: 4vw;
        height: 2.5vw;
      }

      .return {
        width: 1.5vw;
        height: 1.5vw;
        margin: 0.5vw 1.25vw;
      }

      .allBox {
        position: relative;

        .search {
          width: 36.4vw;
          padding: 0.2vw 0.52vw;
          border: 1px solid #4eaddc;
          border-radius: 1.04vw;
          font-size: 1vw;
          font-family: PingFang SC;
          color: #4eaddc;
          font-weight: 400;
          display: flex;
          align-content: center;
          justify-content: space-between;

          .sleft {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;

            .searchimg {
              display: flex;
              align-items: center;
              justify-content: center;
              color: #4eaddc;

              >img {
                width: 1vw;
                margin: 0 1vw 0 0.5vw;
              }
            }

            .input {
              outline: none;
              font-size: 1vw;
              background-color: transparent;
              border: none;
              color: #4eaddc;
              border-left: 1px solid #4eaddc;
              padding-left: 1vw;
              height: 2vw;

            }

            .input::placeholder {
              color: #4eaddc;
              opacity: 1;
              font-size: 1vw;
            }
          }

          .sright {
            height: 2vw;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            border-left: 1px solid #4eaddc;
            padding: 0 1.04vw;
          }
        }

        .selects {
          z-index: 99;
          position: absolute;
          border-radius: 1vw;
          left: 0;
          top: 3vw;
          padding: 0.5vw 1vw;
          font-size: 1.25vw;
          background-color: white;
          border: 1px solid #4eaddc;
          font-size: 1.25vw;
          font-family: PingFang SC;
          color: #4eaddc;

          .selectA {
            color: #4eaddc;
          }
        }

        .chartBox {
          width: 100%;
          border-radius: 0.4vw;
          position: absolute;
          left: 0;
          top: 4vw;
          background: #ffffff;
          z-index: 5;

          #main {
            width: 35vw;
            height: 35vw;
            margin: 0.5vw auto;
          }
        }
      }
    }
  }
}</style>
