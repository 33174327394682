import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Foot from '@/components/foot.vue'
import Head from '@/components/head.vue'

import VillageHead from '@/components/village/Head.vue'
import VillageFoot from '@/components/village/foot.vue'

import Foot2 from '@/components/redtour/Footer.vue'
import Head2 from '@/components/redtour/Head.vue'

import Foot3 from '@/components/corridor/ZkFooter.vue'
import Head3 from '@/components/corridor/ZkHeader.vue'

import Foot4 from '@/components/openyear/Footer.vue'
import Head4 from '@/components/openyear/Head.vue'

import Foot5 from '@/components/culture/foot.vue'
import Head5 from '@/components/culture/Head.vue'

import { IsPC, getBasicInfo } from '@/utils'

const router = new VueRouter({
  // base: 'nt',
  mode: "history",
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      components: {
        default: () => import('@/views/Home.vue'),
        footer: Foot
      },
      meta: {
        index: 1,
        title: "首页"
      }
    },
    {
      path: '/login',
      name: 'login',
      components: {
        default: () => import('@/views/Login.vue'),
        footer: Foot
      },
      meta: {
        index: 0,
        title: "登录页"
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
      meta: {
        index: 2,
        title: "关于页"
      }
    },
    {//全局搜索
      path: '/CyView',
      name: 'CyView',
      components: {
        default: () => import('@/views/CyView.vue'),
      },
      meta: {
        index: 3,
        title: "词云"
      }
    },
    /*人物数据库============================================================*/
    {//人物首页
      path: '/figure/index',
      name: 'figure',
      components: {
        default: () => import('@/views/figure/index.vue'),
        footer: Foot
      },
      meta: {
        index: 5,
        title: "首页"
      }
    },
    {//人物列表页
      path: '/figure/list',
      name: 'figurelist',
      components: {
        header: Head,
        default: () => import('@/views/figure/figures.vue'),
        footer: Foot
      },
      meta: {
        index: 6,
        title: "列表页"
      }
    },
    {//人物详情页
      path: '/figure/detail',
      name: 'figuredetail',
      components: {
        header: Head,
        default: () => import('@/views/figure/detail.vue'),
        footer: Foot
      },
      meta: {
        index: 7,
        title: "详情页"
      }
    },
    {//人物视频页
      path: '/figure/literature/:id([0-9a-z]{24})',
      name: 'literature',
      components: {
        header: Head,
        default: () => import('@/views/figure/video.vue'),
        footer: Foot
      },
      meta: {
        index: 8,
        title: "文献详情"
      }
    },
    {//人物搜索页
      path: '/figure/search',
      name: 'figure-search',
      components: {
        header: Head,
        default: () => import('@/views/figure/Search.vue'),
        footer: Foot
      },
      meta: {
        index: 9,
        title: "搜索"
      }
    },
    {
      path: '/figure/videolist',
      name: 'figure-videolist',
      components: {
        header: Head,
        default: () => import('@/views/figure/videolist.vue'),
        footer: Foot
      },
      meta: {
        index: 10,
        title: "视频列表"
      }
    },
    {
      path: '/figure/videodetail',
      name: 'figure-videodetail',
      components: {
        header: Head,
        default: () => import('@/views/figure/videodetail.vue'),
        footer: Foot
      },
      meta: {
        index: 11,
        title: "视频详情页"
      }
    },
    {
      path: '/figure/imagelist',
      name: 'figure-imagelist',
      components: {
        header: Head,
        default: () => import('@/views/figure/imagelist.vue'),
        footer: Foot
      },
      meta: {
        index: 12,
        title: "图集列表"
      }
    },
    {
      path: '/figure/imagedetail',
      name: 'figure-imagedetail',
      components: {
        header: Head,
        default: () => import('@/views/figure/imagedetail.vue'),
        footer: Foot
      },
      meta: {
        index: 13,
        title: "图集详情页"
      }
    },
    {
      path: '/figure/audiolist',
      name: 'figure-audiolist',
      components: {
        header: Head,
        default: () => import('@/views/figure/audiolist.vue'),
        footer: Foot
      },
      meta: {
        index: 14,
        title: "音频列表"
      }
    },
    {
      path: '/figure/audiodetail',
      name: 'figure-audiodetail',
      components: {
        header: Head,
        default: () => import('@/views/figure/audiodetail.vue'),
        footer: Foot
      },
      meta: {
        index: 15,
        title: "音频详情页"
      }
    },
    {
      path: '/figure/textlist',
      name: 'figure-textlist',
      components: {
        header: Head,
        default: () => import('@/views/figure/textlist.vue'),
        footer: Foot
      },
      meta: {
        index: 16,
        title: "文档列表"
      }
    },
    {
      path: '/figure/textdetail',
      name: 'figure-textdetail',
      components: {
        header: Head,
        default: () => import('@/views/figure/textdetail.vue'),
        footer: Foot
      },
      meta: {
        index: 17,
        title: "文档详情页"
      }
    },
    {//人物文献库看板
      path: '/figure/statistics',
      name: 'figurestatistics',
      components: {
        default: () => import('@/views/figure/statistics'),
      },
      meta: {
        index: 18,
        title: "统计图"
      }
    },

    /*村落数据库============================================================*/
    {//村落首页
      path: '/village/index',
      name: 'village',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/index.vue'),
        footer: Foot
      },
      meta: {
        index: 20,
        title: "村落首页"
      }
    },
    // {//村落列表页
    //   path: '/village/list',
    //   name: 'villages',
    //   components: {
    //     header: Head,
    //     default: () => import('@/views/village/villages.vue'),
    //     footer: Foot
    //   },
    //   meta: {
    //     index: 21,
    //     title: "村落列表"
    //   }
    // },
    {//村落列表页
      path: '/village/villagelist',
      name: 'villageslist',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/villagelist.vue'),
        footer: Foot
      },
      meta: {
        index: 22,
        title: "村落馆"
      }
    },
    {//村落vr首页
      path: '/village/indexvr',
      name: 'villagevridnex',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/indexvr.vue'),
        footer: Foot
      },
      meta: {
        index: 23,
        title: "VR专题馆"
      }
    },
    {//村落vr详情
      path: '/village/tour',
      name: 'villagevrdetail',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/tour.vue'),
        footer: Foot
      },
      meta: {
        index: 24,
        title: "VR全景"
      }
    },
    {//村落详情页
      path: '/village/detail',
      name: 'villagedetail',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/detail.vue'),
        footer: Foot
      },
      meta: {
        index: 25,
        title: "村落详情"
      }
    },
    {//村落详情页
      path: '/village/videodetail',
      name: 'villagevideodetail',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/videodetail.vue'),
        footer: Foot
      },
      meta: {
        index: 26,
        title: "视频详情页"
      }
    },
    {//村落vr首页
      path: '/village/search',
      name: 'village-search',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/search.vue'),
        footer: Foot
      },
      meta: {
        index: 27,
        title: "搜索页"
      }
    },
    // {//村落VR详情页
    //   path: '/village/vr/:id',
    //   name: 'villagedetailvr',
    //   components: {
    //     default: () => import('@/views/village/villagedetailvr.vue'),
    //     footer: Foot
    //   },
    //   meta: {
    //     index: 28,
    //     title: "VR村落"
    //   }
    // },
    {//某村落视频列表页
      path: '/village/:id/videos/:tag?',
      name: 'villagevideos',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/villagevideolist.vue'),
        footer: Foot
      },
      meta: {
        index: 29,
        title: "村落视频列表页"
      }
    },
    {//某村落图集列表页
      path: '/village/:id/images/:tag?',
      name: 'villageimages',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/villageimagelist.vue'),
        footer: Foot
      },
      meta: {
        index: 30,
        title: "村落图集列表页"
      }
    },
    {//某村落图集列表页
      path: '/village/:id/audios/:tag?',
      name: 'villageaudio',
      components: {
        default: () => import('@/views/village/villageaudiolist.vue'),
        footer: Foot
      },
      meta: {
        index: 42,
        title: "视频列表页"
      }
    },
    {
      path: '/village/:id/wenbens/:tag?',
      name: 'villagetext',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/villagewenbenlist.vue'),
        footer: Foot
      },
      meta: {
        index: 43,
        title: "文本列表"
      }
    },
    // {//所有村落图集列表页
    //   path: '/village/videos',
    //   name: 'villages-videos',
    //   components: {
    //     default: () => import('@/views/village/videos.vue'),
    //     footer: Foot
    //   },
    //   meta: {
    //     index: 31,
    //     title: "村落视频列表",
    //     type: "village"

    //   }
    // },
    {//所有村落音频列表页
      path: '/village/audiolist',
      name: 'villages-audiolist',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/audiolist.vue'),
        footer: Foot
      },
      meta: {
        index: 32,
        title: "村落音频列表",
      }
    },
    {//所有村落图集列表页
      path: '/village/imagelist',
      name: 'villages-imagelist',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/imagelist.vue'),
        footer: Foot
      },
      meta: {
        index: 33,
        title: "村落图集列表"
      }
    },
    {//所有村落图集列表页
      path: '/village/textlist',
      name: 'villages-textlist',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/wenbenlist.vue'),
        footer: Foot
      },
      meta: {
        index: 34,
        title: "村落文档列表"
      }
    },
    {//所有村落图集列表页
      path: '/village/videolist',
      name: 'villages-videolist',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/videolist.vue'),
        footer: Foot
      },
      meta: {
        index: 35,
        title: "村落视频列表"
      }
    },
    // {//所有村落图集列表页
    //   path: '/village/images/:tag?',
    //   name: 'villages-images',
    //   components: {
    //     default: () => import('@/views/village/imagelist.vue'),
    //     footer: Foot
    //   },
    //   meta: {
    //     index: 36,
    //     title: "村落图集"        
    //   }
    // },
    // {//人物视频页
    //   path: '/village/video/:id',
    //   name: 'villagevideo',
    //   components: {
    //     header: Head,
    //     default: () => import('@/views/village/video.vue'),
    //     footer: Foot
    //   },
    //   meta: {
    //     index: 37,
    //     title: "视频详情"
    //   }
    // },
    {//村落vr首页this.$route.params.id
      path: '/village/message',
      name: 'villagemessage',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/message.vue'),
        footer: Foot
      },
      meta: {
        index: 38,
        title: "留言馆"
      }
    },

    {//images
      path: '/village/imagedetail',
      name: 'imagedetail',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/imagedetail.vue'),
        footer: Foot
      },
      meta: {
        index: 39,
        title: "图集详情"
      }
    },
    {//村落音频详情页
      path: '/village/audiodetail',
      name: 'villageaudiodetail',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/audiodetail.vue'),
        footer: Foot
      },
      meta: {
        index: 40,
        title: "音频详情"
      }
    },
    {//村落文本详情页
      path: '/village/textdetail',
      name: 'villagetextdetail',
      components: {
        header: VillageHead,
        default: () => import('@/views/village/wenbendetail.vue'),
        footer: Foot
      },
      meta: {
        index: 41,
        title: "文档详情"
      }
    },
    
    {//村落看板
      path: '/village/dashboard',
      name: 'villagestat',
      components: {
        default: () => import('@/views/village/dashboard.vue'),
      },
      meta: {
        index: 44,
        title: "统计图"
      }
    },

    /*文化走廊数据库============================================================*/
    {//首页
      path: '/corridor/index',
      name: 'corridor',
      components: {
        default: () => import('@/views/corridor/index.vue'),
      },
      meta: {
        index: 50,
        title: "首页"  
      }
    },
    {//长廊搜索
      path: '/corridor/search',
      name: 'corridor-search',
      components: {
        default: () => import('@/views/corridor/Search.vue'),
        footer: Foot3
      },
      meta: {
        index: 51,
        title: "搜索"  
      }
    },
    {//某机构图集
      path: '/corridor/images',
      name: 'org_images',
      components: {
        default: () => import('@/views/corridor/Images.vue'),
      },
      meta: {
        index: 53,
        title: "图集列表"  
      }
    },
    {//某机构视频
      path: '/corridor/videos',
      name: 'org_videos',
      components: {
        default: () => import('@/views/corridor/Videos.vue'),
      },
      meta: {
        index: 54,
        title: "视频列表"  
      }
    },
    {//机构图集
      path: '/corridor/image',
      name: 'corridor_image',
      components: {
        default: () => import('@/views/corridor/Image.vue'),
      },
      meta: {
        index: 55,
        title: "图集详情"  
      }
    },
    {//机构视频
      path: '/corridor/video',
      name: 'corridor_video',
      components: {
        default: () => import('@/views/corridor/Video.vue'),
      },
      meta: {
        index: 56,
        title: "视频详情"  
      }
    },
    {//机构视频
      path: '/corridor/circle',
      name: 'circle',
      components: {
        default: () => import('@/views/corridor/Circle.vue'),
      },
      meta: {
        index: 57,
        title: "圈层",
      }
    },
    {//机构详情
      path: '/corridor/organization',
      name: 'organization',
      components: {
        default: () => import('@/views/corridor/organization.vue'),
      },
      meta: {
        index: 58,
        title: "详情"  
      }
    },
    {//机构音频
      path: '/corridor/audio',
      name: 'corridor_audio',
      components: {
        default: () => import('@/views/corridor/Audio.vue'),
      },
      meta: {
        index: 59,
        title: "音频详情"  
      }
    },
    {//机构文档
      path: '/corridor/text',
      name: 'corridor_text',
      components: {
        default: () => import('@/views/corridor/Text.vue'),
      },
      meta: {
        index: 60,
        title: "文档详情"  
      }
    },
    {//文化走廊文献库看板
      path: '/corridor/statistics',
      name: 'corridorstatistics',
      components: {
        default: () => import('@/views/corridor/statistics'),
      },
      meta: {
        index: 61,
        title: "统计图"  
      }
    },
    {
      path: '/corridor/vtour',//全景图
      name: 'vtour',
      components: {
        default: () => import('@/components/corridor/ZkVtour.vue'),
      },
      meta: {
        index: 62,
        title: "全景图"  
      }
    },

    /*红色数据库============================================================*/
    {//红色列表页
      path: '/redtour/index',
      name: 'redtour',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/index.vue'),
        footer: Foot2
      },
      meta: {
        index: 70,
        title: "首页"  
      }
    },
    {//红色列表页
      path: '/redtour/history',
      name: 'history',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/history.vue'),
        footer: Foot2
      },
      meta: {
        index: 71,
        title: "党史百年"  
      }
    },
    {//红色列表页
      path: '/redtour/list',
      name: 'bases',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/bases.vue'),
        footer: Foot2
      },
      meta: {
        index: 72,
        title: "列表页"  
      }
    },
    {//红色列表页
      path: '/redtour/vr',
      name: 'redvr',
      components: {
        default: () => import('@/views/redtour/tour.vue'),
      },
      meta: {
        index: 73,
        title: "VR全景"  
      }
    },
    {//红色文献库看板
      path: '/redtour/statistics',
      name: 'redtourstatistics',
      components: {
        default: () => import('@/views/redtour/statistics'),
      },
      meta: {
        index: 74,
        title: "统计图"  
      }
    },
    {
      path: '/redtour/videolist',
      name: 'redtour-videolist',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/videolist.vue'),
        footer: Foot2
      },
      meta: {
        index: 75,
        title: "红色视频"  
      }
    },
    {
      path: '/redtour/videodetail/:id([0-9a-z]{24})',
      name: 'redtour-videodetail',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/videodetail.vue'),
        footer: Foot2
      },
      meta: {
        index: 76,
        title: "视频详情页"  
      }
    },
    {
      path: '/redtour/imagelist',
      name: 'redtour-imagelist',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/imagelist.vue'),
        footer: Foot2
      },
      meta: {
        index: 77,
        title: "图集"  
      }
    },
    {
      path: '/redtour/imagedetail/:id([0-9a-z]{24})',
      name: 'redtour-imagedetail',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/videodetail.vue'),
        footer: Foot2
      },
      meta: {
        index: 78,
        title: "图集详情页"  
      }
    },
    {
      path: '/redtour/audiolist',
      name: 'redtour-audiolist',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/audiolist.vue'),
        footer: Foot2
      },
      meta: {
        index: 79,
        title: "音频列表"  
      }
    },
    {
      path: '/redtour/audiodetail/:id([0-9a-z]{24})',
      name: 'redtour-audiodetail',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/audiodetail.vue'),
        footer: Foot2
      },
      meta: {
        index: 80,
        title: "音频详情页"  
      }
    },
    {
      path: '/redtour/textlist',
      name: 'redtour-textlist',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/textlist.vue'),
        footer: Foot2
      },
      meta: {
        index: 81,
        title: "文档列表"  
      }
    },
    {
      path: '/redtour/textdetail/:id([0-9a-z]{24})',
      name: 'redtour-textdetail',
      components: {
        header: Head2,
        default: () => import('@/views/redtour/textdetail.vue'),
        footer: Foot2
      },
      meta: {
        index: 82,
        title: "文档详情页"  
      }
    },

    /*图片文献库数据库============================================================*/
    {//首页
      path: '/oldphoto/index',
      name: 'oldphoto',
      components: {
        default: () => import('@/views/oldphoto/index.vue'),
        footer: Foot
      },
      meta: {
        index: 90,
        title: "首页"
      }
    },
    {//图片文献库详情页
      path: '/oldphoto/detail',
      name: 'oldphotodetail',
      components: {
        default: () => import('@/views/oldphoto/detail.vue'),
        footer: Foot
      },
      meta: {
        index: 91,
        title: "详情页"
      }
    },

    {//图片文献库列表页
      path: '/oldphoto/list',
      name: 'oldphotolist',
      components: {
        default: () => import('@/views/oldphoto/list.vue'),
        footer: Foot
      },
      meta: {
        index: 92,
        title: "图片文献库列表"
      }
    },

    {//图片文献库看板
      path: '/oldphoto/statistics',
      name: 'statistics',
      components: {
        default: () => import('@/views/oldphoto/statistics'),
      },
      meta: {
        index: 93,
        title: "统计图"
      }
    },
    {
      path: '/oldphoto/oldphotosearch',
      name: 'oldphotosearch',
      components: {
        default: () => import('@/views/oldphoto/search.vue'),
        footer: Foot
      },
      meta: {
        index: 94,
        title: "搜索页"
      }
    },
    {
      path: '/oldphoto/compare',
      name: 'oldphotocompare',
      components: {
        default: () => import('@/views/oldphoto/compare.vue'),
        footer: Foot
      },
      meta: {
        index: 95,
        title: "比较页"
      }
    },
    /*25周年数据库============================================================*/
    {//25周年首页
      path: '/openyear/index',
      name: 'openyear',
      components: {
        header: Head4,
        default: () => import('@/views/openyear/index.vue'),
        footer: Foot4
      },
      meta: {
        index: 100,
        title: "25周年首页"
      }
    },
    {//25周年留言页
      path: '/openyear/message',
      name: 'message',
      components: {
        header: Head4,
        default: () => import('@/views/openyear/message.vue'),
        footer: Foot4
      },
      meta: {
        index: 101,
        title: "留言页"
      }
    },
    {//25周年全景页
      path: '/openyear/vr/:id',
      name: 'openyeartour',
      components: {
        default: () => import('@/views/openyear/tour.vue'),
      },
      meta: {
        index: 102,
        title: "全景页"
      }
    },
    //文旅库
    {
      path: "/culture/index",
      name: "culture",
      components: {
        default: () => import("@/views/culture/index.vue"),
        footer: Foot5
      },
      meta: {
        index: 103,
        title: "文旅篇",
        keepAlive:true
      },
    },
    {
      path: "/culture/editTourism",
      name: "editView",
      components: {
        default: () => import("@/views/culture/EditView.vue"),
      },
      meta: {
        index: 104,
        title: "打卡详情"
      },
    },
    {
      path: "/culture/archives/:id([0-9a-z]{24})",
      name: "archives",
      components: {
        default: () => import("@/views/culture/archives.vue"),
      },
      meta: {
        index: 108,
        title: "文献集"
      },
    },
    {
      path: "/culture/search",
      name: "database",
      components: {
        default: () => import("@/views/culture/documentDatabase.vue"),
      },
      meta: {
        index: 109,
        title: "文献库分类"
      },
    },
    {//视频
      path: '/culture/videodetail',
      name: 'culture-videodetail',
      components: {
        header: Head5,
        default: () => import('@/views/culture/videodetail.vue'),
        footer: Foot
      },
      meta: {
        index: 110,
        title: "视频详情"
      }
    },
    {//音频详情页
      path: '/culture/audiodetail',
      name: 'culture-audiodetail',
      components: {
        header: Head5,
        default: () => import('@/views/culture/audiodetail.vue'),
        footer: Foot
      },
      meta: {
        index: 111,
        title: "音频详情"
      }
    },
    {//images
      path: '/culture/imagedetail',
      name: 'culture-imagedetail',
      components: {
        header: Head5,
        default: () => import('@/views/culture/imagedetail.vue'),
        footer: Foot
      },
      meta: {
        index: 112,
        title: "图集详情"
      }
    },
    {//音频详情页
      path: '/culture/textdetail',
      name: 'culture-textdetail',
      components: {
        header: Head5,
        default: () => import('@/views/culture/textdetail.vue'),
        footer: Foot
      },
      meta: {
        index: 113,
        title: "文本详情"
      }
    },
    {//所有文旅库音频列表页
      path: '/culture/audiolist',
      name: 'culture-audiolist',
      components: {
        header: Head5,
        default: () => import('@/views/culture/audiolist.vue'),
        footer: Foot
      },
      meta: {
        index: 114,
        title: "文旅库音频列表"
      }
    },
    {//所有文旅库图集列表页
      path: '/culture/imagelist',
      name: 'cultures-imagelist',
      components: {
        header: Head5,
        default: () => import('@/views/culture/imagelist.vue'),
        footer: Foot
      },
      meta: {
        index: 115,
        title: "文旅库图集列表"
      }
    },
    {//所有文旅库文本列表页
      path: '/culture/textlist',
      name: 'cultures-textlist',
      components: {
        header: Head5,
        default: () => import('@/views/culture/textlist.vue'),
        footer: Foot
      },
      meta: {
        index: 116,
        title: "文旅库文档列表"
      }
    },
    {//所有文旅库视频列表页
      path: '/culture/videolist',
      name: 'cultures-videolist',
      components: {
        header: Head5,
        default: () => import('@/views/culture/videolist.vue'),
        footer: Foot
      },
      meta: {
        index: 117,
        title: "文旅库视频列表"
      }
    },
    {
      path: "/culture/dataList",
      name: "dataList",
      components: {
        default: () => import("@/views/culture/DataList.vue"),
      },
      meta: {
        index: 118,
        title: "旅游点分类"
      },
    },
    {
      path: '/culture/dashboard',
      name: 'culturestat',
      components: {
        default: () => import('@/views/culture/dashboard.vue'),
      },
      meta: {
        index: 119,
        title: "统计图"
      }
    },
    {
      path: "/culture/VREdit",
      name: "culture/VREdit",
      meta: {
          index: 120,
          title: "文旅VR"
      }
    },
    {//test
      path: '/test',
      name: 'test',
      components: {
        default: () => import('@/components/oldphoto/Emap.vue'),
      }
    },
  ]
})

router.afterEach((to, from, next) => {
  var xfrom = IsPC() ? "PC" : "H5";
  var url = window.location.origin;
  if (xfrom == 'H5') {
    if(to.fullPath.indexOf("/pages")==-1) {
      url += (router.mode != "history")?"/#/pages":"/pages";      
    }
    console.log("Next page===>",url + to.fullPath);
    window.location.href = url + to.fullPath;
  }else{
    if(to.fullPath.indexOf("/pages")!=-1) {
      window.location.href = url + to.fullPath.replace("/pages",'');
    }
  }
  
  window.scrollTo(0, 0);
  var stats = {};
  var sstats = localStorage.getItem("stats");
  if (sstats && sstats.length > 1)
    stats = JSON.parse(sstats)

  if (to.path in stats)
    stats[to.fullPath].click += 1;
  else
    stats[to.fullPath] = { "id": to.meta.index, "title": to.meta.title, "click": 1, "from": xfrom };

  localStorage.setItem("stats", JSON.stringify(stats));
});

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
