<template>
    <div class="footer">
        <div class="footbox_8 flex-row">
        <img
          class="image_19"
          referrerpolicy="no-referrer"
          src="@/assets/img/nstsg_logo2.png"
        />
        <span class="footparagraph_1">
          电话：&nbsp;0755-26520380 &nbsp;地址：&nbsp;深圳南山区常兴路176号
          <br />          
          南山图书馆&nbsp;Copyright&nbsp;©&nbsp;2022&nbsp;<a href="https://beian.miit.gov.cn/" style="text-decoration:none">粤ICP&nbsp;备&nbsp;05054455号</a>
          <br/>
        </span>
        <div class="block_4 flex-col"></div>
        <div class="block_5 flex-col justify-between">
          <span class="foottext_22">关注我们</span>
          <div class="footbox_9 flex-col" title="0755-26520380 "> 
          </div>
        </div>
        <div class="block_6 flex-col">
          <p class="weixin-img"><img src='@/assets/img/weixin-erweima.png' width="120px" height="120px"/></p>
        </div>
        <div class="block_7 flex-col">
          <p><img class="weibo-img" src='@/assets/img/weibo-erweima.png'  width="120px" height="120px"/></p>
        </div>
      </div>
      
    </div>
</template>
<script>
export default {
    data () {
        return {

        }
    },
    methods:{
        toindex () {
            this.$router.push('/')
        }
    }
}
</script>
<style lang="less" scoped>
.footer{
        background-color: rgba(255, 255, 255, 1);
        margin-top: 30px;
        min-height: 10.5rem;
    }
 
.footbox_8 {
  width: 1121px;
  height: 61px; 
}

.image_19 {
  width: 251px;
  height: 56px;
  margin-top: 5px;
}

.footparagraph_1 {
  width: 423px;
  height: 60px;
  overflow-wrap: break-word;
  color: black;
  font-size: 15px;
  font-family: PingFangSC-Semibold;
  text-align: left;
  line-height: 30px;
  margin-left: 204px;
}

.block_4 {
  width: 1px;
  height: 50px;
  background: url('@/assets/img/line.png') -1px -1px
    no-repeat;
  background-size: 2px 51px;
  margin: 6px 0 0 32px;
}

.block_5 {
  width: 56px;
  height: 60px;
  margin-left: 42px;
}

.foottext_22 {
  width: 56px;
  height: 20px;
  overflow-wrap: break-word;
  color: gray;
  font-size: 13px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}

.footbox_9 {
  width: 26px;
  height: 26px;
  background: url('@/assets/img/phone.png') -1px -1px
    no-repeat;
  background-size: 28px 27px;
  margin: 14px 0 0 1px;
}
.photo-txt{
  display:none;
}
.footbox_9:hover .photo-txt {
  display: block;
}
.block_6 {
  width: 26px;
  height: 26px;
  background:   url('@/assets/img/icon2.png') 
    0px -1px no-repeat;
  background-size: 27px 27px;
  margin: 34px 0 0 13px;
}
.weixin-img{
  display: none; 
   margin-top: -120px;
  margin-left: -50px;
}
.block_6:hover .weixin-img {
  display: block;
}
.block_7 {
  width: 27px;
  height: 25px;
  background:   url('@/assets/img/weibo2.png') 
    0px -1px no-repeat;
  background-size: 28px 26px;
  margin: 35px 0 0 46px;
}
.weibo-img{
  display: none; 
  margin-top: -120px;
  margin-left: -50px;
}
.block_7:hover .weibo-img {
  display: block;
}
.foottext_23 {
  width: 337px;
  height: 20px;
  overflow-wrap: break-word;
  color: gray;
  font-size: 14px;
  font-family: PingFangSC-Light;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 68px 0 193px -780px;
}

.footbox_10 {
  position: absolute;
  left: 42px;
  top: 118px;
  width: 1832px;
  height: 1px;
  background:  url('@/assets/img/line2.png') 
    0px 0px no-repeat;
  background-size: 1834px 1px;
}

.footbox_11 {
  position: absolute;
  left: 42px;
  top: 1251px;
  width: 1832px;
  height: 1px;
  background: url('@/assets/img/line2.png') 
    0px 0px no-repeat;
  background-size: 1834px 1px;
}

</style>
